.ant-message .anticon {
    top: -2px;
}
::-webkit-scrollbar {
    width: 7px;
}
::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #dedede;
}
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}
@tailwind base;
@tailwind components;
@tailwind utilities;
